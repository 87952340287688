//
//
//
//
//
//
//
//
//

export default {
  props: {
    rangeOfMovement: { type: Number, required: false, default: 15 },
  },
  computed: {
    defaultedYOffset() {
      return {
        start: 0,
        end: 0,
        ...this.yOffset,
      }
    },
    defaultSlotText() {
      return this.$slots.default[0].text
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches

      const { title, titleContent } = this.$refs
      const gsap = this.$gsap

      // Parallax animation
      if (!prefersReducedMotion)
        gsap.fromTo(
          title,
          { yPercent: this.rangeOfMovement * -1 },
          {
            yPercent: this.rangeOfMovement,
            scrollTrigger: { scrub: true, trigger: title },
          }
        )

      // NOTE: default scroll Trigger for reveal animation
      const scrollTrigger = {
        trigger: title,
        start: 'top bottom-=20%',
        once: true,
      }

      // Reveal animation
      if (prefersReducedMotion)
        gsap.fromTo(
          titleContent,
          { filter: 'blur(10px)', opacity: 0 },
          { filter: 'blur(0px)', opacity: 1, scrollTrigger }
        )
      else
        gsap.fromTo(
          titleContent,
          { yPercent: 110 },
          { yPercent: 0, duration: 0.75, ease: 'power2.out(1.5)', scrollTrigger }
        )
    },
  },
}
